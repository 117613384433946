import { FC, useState } from "react";
import Collapse from "@kunukn/react-collapse";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS, IMAGES } from "../../assets";

import { useWindowDimensions } from "../../hooks";

import Item from "./Item";

const List = [
  {
    title: "Реєстрація та ліквідація бізнесу",
    shortList: [
      "Розробка установчих документів юридичної особи",
      "Реєстрація ФОП, ТОВ, ПП, ФГ, ОСББ, інші СПД -“під ключ”",
      "Супровід купівлі-продажу корпоративних прав",
    ],
    fullList: [
      "Розробка установчих документів юридичної особи",
      "Реєстрація ФОП, ТОВ, ПП, ФГ, ОСББ, інші СПД -“під ключ”",
      "Обрання оптимальної організаційно-правової форми для бізнесу та підбір КВЕДів",
      "Супровід перетворення, злиття, приєднання, поглинання і виділу юридичних осіб",
      "Супровід купівлі-продажу корпоративних прав",
      "Ліквідація фізичних осіб – підприємців та юридичних осіб",
    ],
  },
  {
    title: "Представництво в судах",
    shortList: [
      "Досудове врегулювання спорів",
      "Спори у сфері банківського права",
      "Спори зі страховими компаніями",
    ],
    fullList: [
      "Досудове врегулювання спорів",
      "Представництво та захист інтересів клієнта в судах усіх інстанцій та юрисдикцій (цивільних, господарських, корпоративних, адміністративних, трудових спорах)",
      "Корпоративні спори",
      "Спори у сфері банківського права",
      "Спори зі страховими компаніями",
      "Податкові та митні спори",
      "Спори щодо договірного права",
      "Стягнення дебіторської заборгованості",
      "Трудові спори",
      "Спори щодо нерухомості",
      "Сімейні, спадкові та земельні спори",
      "Супровід виконання судових рішень",
      "Допомога при ДТП",
      "Супровід у справах про керування транспортним засобом у стані спяніння",
    ],
  },
  {
    title: "Земля та нерухомість",
    shortList: [
      "Супровід процесу безоплатної приватизації земельної ділянки",
      "Юридичний аудит об’єкта оренди",
      "Супровід угод купівлі-продажу, оренди нерухомого майна",
    ],
    fullList: [
      "Супровід процесу безоплатної приватизації земельної ділянки",
      "Спори щодо меж земельних ділянок із сусідніми землекористувачами",
      "Оформлення права власності на землю, супровід зміни цільового призначення земельної ділянки",
      "Оформлення прав власності, оренди, постійного користування, суперфіцію, емфітевзису, сервітуту на земельні ділянки",
      "Юридичний аудит об’єкта оренди",
      "Супровід процесу укладення договору оренди житла, внесення змін до нього та дострокового розірвання",
      "Спори про виселення та визнання осіб такими, що втратили право користування житлом",
      "Супровід угод купівлі-продажу, оренди нерухомого майна",
      "Узаконення реконструкції, перепланувань та самочинного будівництва",
    ],
  },
  {
    title: "Кримінально-правовий захист",
    shortList: [
      "Захист інтересів Клієнта під час проведення обшуку",
      "Оскарження судових рішень про накладення арешту на майно",
      "Представництво потерпілих осіб на всіх стадіях кримінального провадження",
    ],
    fullList: [
      "Консультування та захист на всіх стадіях кримінального провадження",
      "Захист інтересів Клієнта під час затримання, оголошення підозри, обрання запобіжного заходу",
      "Підготовлення Клієнта до проведення допиту та участь адвоката під час проведення допиту",
      "Захист інтересів Клієнта під час проведення обшуку",
      "Оскарження судових рішень про накладення арешту на майно",
      "Представництво потерпілих осіб на всіх стадіях кримінального провадження",
      "Вироблення стратегії і тактики захисту від пред’явлених підозр і звинувачень з урахуванням всіх обставин справи",
    ],
  },
  {
    title: "Договірне право",
    shortList: [
      "Правова експертиза діючих договорів на предмет відповідності їх чинному законодавству",
      "Переддоговірна експертиза юридичних документів контрагентів клієнта",
      "Участь у процесі досудового врегулювання договірних спорів",
    ],
    fullList: [
      "Розробка проектів цивільно-правових, господарсько-правових та зовнішньоекономічних договорів під потреби клієнта",
      "Правова експертиза діючих договорів на предмет відповідності їх чинному законодавству",
      "Переддоговірна експертиза юридичних документів контрагентів клієнта",
      "Участь у переговорах в процесі погодження умов договорів та при їх укладенні, в тому числі тих, що вимагають нотаріального посвідчення, державної реєстрації",
      "Перевірка контрагента",
      "Підготовка інших юридичних документів, що забезпечують ведення бізнесу, інвестиційної та іншої діяльності суб’єкта господарювання",
      "Участь у процесі досудового врегулювання договірних спорів",
      "Судовий захист у спорах, що виникають з договірних відносин",
    ],
  },
  {
    title: "Супровід купівлі",
    shortList: [
      "Досудове врегулювання спорів",
      "Розробка та експертна оцінка договорів",
      "Участь у переговорах з контрагентами, при спілкуванні з контролюючими органами, іншими органами державної влади",
    ],
    fullList: [
      "Усні та письмові консультації",
      "Державна реєстрація",
      "Розробка та експертна оцінка договорів",
      "Підготовка юридичних висновків",
      "Участь у переговорах з контрагентами, при спілкуванні з контролюючими органами, іншими органами державної влади",
      "Досудове врегулювання спорів",
      "Представництво у судах",
      "Вирішення трудових спорів",
      "Юридичний супровід окремих проектів",
      "Моніторинг фінансової, майнової та комерційної платоспроможності і благонадійності потенційних ділових партнерів",
      "Кримінально-правовий захист вашої діяльності",
    ],
  },
  {
    title: "Сімейне право",
    shortList: [
      "Супровід процесу розірвання шлюбу",
      "Визнання шлюбу недійсним",
      "Захист інтересів Клієнта з питань, пов’язаних із цивільним шлюбом",
      "Позбавлення батьківських прав",
    ],
    fullList: [
      "Супровід процесу розірвання шлюбу",
      "Визнання шлюбу недійсним",
      "Захист інтересів Клієнта з питань, пов’язаних із цивільним шлюбом",
      "Складання договорів в сфері сімейних правовідносин",
      "Встановлення батьківства",
      "Позбавлення батьківських прав",
      "Визначення місця проживання неповнолітніх дітей після розірвання шлюбу",
      "Встановлення порядку та тривалості побачень з дітьми",
      "Стягнення аліментів",
      "Поділ спільного майна подружжя",
    ],
  },
];

const Service: FC = () => {
  const { width } = useWindowDimensions();
  const [selectedItem, setSelectedItem] = useState<null | number>(null);

  return (
    <section className={styles.wrapper} id="service">
      <div className="container">
        <h2 className={cls("subtitle", styles.wrapper_subtitle)}>
          Наші Послуги
        </h2>

        <div className={styles.wrapper_list}>
          {List.map((item, index) => (
            <div key={index} className={styles.wrapper_list_item}>
              <Item
                title={item.title}
                shortList={item.shortList}
                fullList={item.fullList}
              />
            </div>
          ))}
        </div>

        {/* {width > 464 ? (
          <div className={styles.wrapper_service}>
            {List.map((item, index) => (
              <div className={styles.wrapper_service_item} key={index}>
                <p className={styles.wrapper_service_item_title}>
                  {item.title}
                </p>

                <div className={styles.wrapper_service_item_info}>
                  <h5 className={styles.wrapper_service_item_info_title}>
                    {item.title}
                  </h5>

                  <ul className={styles.wrapper_service_item_info_list}>
                    {item.items.map((s, index) => (
                      <li key={index}>{s}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            {List.map((item, index) => (
              <div key={index} className={styles.callapse_item}>
                <div
                  className={styles.callapse_item_header}
                  onClick={() => {
                    if (index === selectedItem) {
                      setSelectedItem(null);
                    } else {
                      setSelectedItem(index);
                    }
                  }}
                >
                  <p className={styles.callapse_item_header_title}>
                    {item.title}
                  </p>
                  <div
                    className={cls(styles.callapse_item_header_icon, {
                      [styles.callapse_item_header_icon_active]:
                        selectedItem === index,
                    })}
                  >
                    <ICONS.Arrow />
                  </div>
                </div>

                <Collapse isOpen={selectedItem === index}>
                  <div className={styles.callapse_item_body}>
                    <ul className={styles.wrapper_service_item_info_list}>
                      {item.items.map((s, index) => (
                        <li key={index}>{s}</li>
                      ))}
                    </ul>
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        )} */}
      </div>
    </section>
  );
};

export default Service;
