import { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { Button } from "../../../components";
import { callToPhone } from "../../../utils";

type Props = {
  member: any;
  isActive: boolean;
};

const Item: FC<Props> = ({ member, isActive }) => {
  return (
    <div className={cls(styles.wrapper)}>
      <div className={cls(styles.wrapper_item)}>
        <header className={styles.wrapper_item_header}>
          <div
            className={styles.wrapper_item_header_img}
            style={{ backgroundImage: `url(${member.avatar})` }}
          />

          <div className={styles.wrapper_item_header_info}>
            <h4 className={styles.wrapper_item_header_info_name}>
              {member.name}
            </h4>

            <p className={styles.wrapper_item_header_info_description}>
              {member.description}
            </p>
          </div>
        </header>

        <p className={styles.wrapper_item_seperator}>Спеціалізації:</p>

        <ul className={styles.wrapper_item_list}>
          {member.items.map((item: string, index: number) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <div className={styles.wrapper_item_button}>
          <Button
            small
            label="Отримати консультацію"
            onClick={() => callToPhone(member.phone)}
          />
        </div>
      </div>
    </div>
  );
};

export default Item;
