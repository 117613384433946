import topBackground from "./top-bg.jpg";
import lawyer from "./lawyer.jpg";
import teamBg from "./team-bg.jpg";
import law from "./law.jpg";
import topBanner from "./top-banner.png";

import member1 from "./member1.png";
import member2 from "./member2.jpg";
import member3 from "./member3.jpg";

const IMAGES = {
  topBackground,
  lawyer,
  teamBg,
  law,
  topBanner,

  member1,
  member2,
  member3,
};

export default IMAGES;
