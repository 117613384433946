import { FC } from "react";
import GoogleMapReact from "google-map-react";
// import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import styles from "./styles.module.scss";

import { ICONS } from "../../assets";

import { Button } from "../../components";
import { callToPhone } from "../../utils";

const Contacts = [
  {
    icon: ICONS.Location,
    items: [
      {
        label: "м. Львів, вул. М. Гоголя 12/4",
        href: "https://www.google.com/maps/place/Solister/@49.8412442,24.0165639,15z/data=!4m6!3m5!1s0x473add91c622f95b:0xb574b9626211fe18!8m2!3d49.8412442!4d24.0165639!16s%2Fg%2F11h0bklj6b?entry=ttu",
      },
    ],
  },
  {
    icon: ICONS.Phone,
    items: [
      { label: "+38 (093) 253 94 32", href: "tel:+380932539432" },
      { label: "+38 (067) 672 87 86", href: "tel:+380676728786" },
    ],
  },
  {
    icon: ICONS.Message,
    items: [
      {
        label: "solisterlawyers@gmail.com",
        href: "mailto:solisterlawyers@gmail.com",
      },
    ],
  },
];

const Footer = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCdP8O94Po4MV8E861n8W1lBVDmLriARl4",
  });

  return (
    <section className={styles.wrapper} id="footer">
      <div className="container">
        <div className={styles.container}>
          <div className={styles.container_left}>
            <div className={styles.container_left_logo}>
              <ICONS.Logo width={200} height={100} />
            </div>

            <div className={styles.container_left_contacts}>
              {Contacts.map((contact, index) => (
                <div
                  key={index}
                  className={styles.container_left_contacts_item}
                >
                  <div className={styles.container_left_contacts_item_icon}>
                    {
                      <contact.icon
                        fill="#B1976C"
                        stroke="#B1976C"
                        color="#B1976C"
                      />
                    }
                  </div>
                  <div className={styles.container_left_contacts_item_items}>
                    {contact.items.map((item, index) => (
                      <div key={index}>
                        <a href={item.href}>{item.label}</a>
                      </div>
                    ))}
                  </div>
                </div>
              ))}

              <div className={styles.container_left_social}>
                <a
                  className={styles.container_left_social_item}
                  href="https://www.facebook.com/solisterlawyer"
                  target="_blank"
                >
                  <ICONS.Facebook />
                </a>
                <a
                  className={styles.container_left_social_item}
                  href="https://www.instagram.com/solister_lawyers?igsh=ejRlb3dnaDNjdjE4"
                  target="_blank"
                >
                  <ICONS.Instagram />
                </a>
                {/* <div className={styles.container_left_social_item}>
                <ICONS.Linkedin />
              </div> */}
              </div>
            </div>

            <div className={styles.container_left_button}>
              <Button
                label="Отримати консультацію"
                onClick={() => callToPhone("+380932539432")}
              />
            </div>
          </div>
          <div className={styles.container_right}>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={{ height: "400px" }}
                center={{ lat: 49.8412442, lng: 24.0165639 }}
                zoom={16}
              >
                <Marker position={{ lat: 49.841201, lng: 24.016583 }} />
              </GoogleMap>
            )}
          </div>
        </div>

        <div className={styles.container_copyright}>2023© Solister</div>
      </div>
    </section>
  );
};

export default Footer;
