import { FC, useMemo, useState, useRef } from "react";
import Slider from "react-slick";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS, IMAGES } from "../../assets";
import Item from "./Item";
import { useWindowDimensions } from "../../hooks";

const responsive = [
  {
    breakpoint: 4000,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 3000,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 464,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
};

const Members = [
  {
    name: "Лемеха Роман Ігорович",
    avatar: IMAGES.member1,
    description:
      "Старший партнер АО «Solister», кандидат юридичних наук. Професійний досвід роботи в галузі права понад 15 років.",
    items: [
      "представництво інтересів у судових органах",
      "корпоративне право",
      "договірне право",
      "сімейне право",
      "юридичний супровід бізнесу",
      "житлові спори",
      "нерухомість",
      "інтелектуальне право",
    ],
    phone: "+380676728786",
  },
  {
    name: "Попко Юрій Ярославович",
    avatar: IMAGES.member2,
    description:
      "Керуючий партнер АО «Solister», кандидат юридичних наук. Професійний досвід роботи в галузі права понад 15 років.",
    items: [
      "земельне право",
      "будівництво та нерухомість",
      "корпоративне право",
      "трудові, договірні спори",
      "супровід бізнесу",
      "представництво інтересів у судових органах",
    ],
    phone: "+380932539432",
  },
  {
    name: "Кучма Роман Андрійович",
    avatar: IMAGES.member3,
    description: "Юрист АО «Solister»",
    items: [
      "реєстрація бізнесу під ключ",
      "договірне право",
      "дозволи та ліцензування",
      "спори, що випливають з трудових відносин",
      "справи про адміністративні правопорушення",
      "податкове право",
    ],
    phone: "+380932539432",
  },
  // {
  //   name: "Кучма Роман Андрійович",
  //   description: "Юрист АО «Solister»",
  //   items: [
  //     "реєстрація бізнесу під ключ",
  //     "договірне право",
  //     "дозволи та ліцензування",
  //     "спори, що випливають з трудових відносин",
  //     "справи про адміністративні правопорушення",
  //     "податкове право",
  //   ],
  // },
  // {
  //   name: "Кучма Роман Андрійович",
  //   description: "Юрист АО «Solister»",
  //   items: [
  //     "реєстрація бізнесу під ключ",
  //     "договірне право",
  //     "дозволи та ліцензування",
  //     "спори, що випливають з трудових відносин",
  //     "справи про адміністративні правопорушення",
  //     "податкове право",
  //   ],
  // },
];

const Team: FC = () => {
  const [selectedItem, setSelectedItem] = useState<null | number>(null);
  const { width } = useWindowDimensions();
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderRef = useRef<any>();

  const countSlides = useMemo(() => {
    if (width > 1024) {
      return Math.ceil(Members.length / 2);
    }

    return Members.length;
  }, []);

  return (
    <section className={styles.wrapper} id="team">
      <div className="container">
        <header className={styles.wrapper_header}>
          <h2 className={cls("subtitle", styles.wrapper_subtitle)}>Команда</h2>

          <div className={styles.wrapper_header_pagination}>
            <button
              className={styles.wrapper_header_pagination_button}
              onClick={() => sliderRef?.current?.slickPrev()}
            >
              <ICONS.ArrowLeft />
            </button>
            <p className={styles.wrapper_header_pagination_label}>
              {currentSlide + 1} / {countSlides}
            </p>
            <button
              className={styles.wrapper_header_pagination_button}
              onClick={() => sliderRef?.current?.slickNext()}
            >
              <ICONS.ArrowRight />
            </button>
          </div>
        </header>

        <Slider
          {...settings}
          ref={sliderRef}
          arrows={false}
          className="carousel-item-padding-40-px"
          responsive={responsive}
          afterChange={(slide) => setCurrentSlide(slide)}
        >
          {Members.map((member, index) => (
            <div key={index}>
              <Item member={member} isActive={selectedItem === index} />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Team;
