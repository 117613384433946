import { FC, ChangeEvent } from "react";
import styles from "./styles.module.scss";

interface InputComponentProps {
  value: string;
  label?: string;
  placeholder?: string;
  error?: string;

  onChange: (value: string) => void;
}

const Input: FC<InputComponentProps> = ({
  label,
  placeholder,
  value,
  error,
  onChange,
}) => {
  const changeName = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={styles.container}>
      {label ? <label className={styles.label}>{label}</label> : null}
      <input
        className={styles.input}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={changeName}
      />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default Input;
