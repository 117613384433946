import { FC, ReactNode, useEffect, useState } from "react";
import Modal from "react-modal";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";

import { ICONS } from "../../assets";

import { Button, Input, PhoneInput, Textarea } from "../";

import { sendForm } from "./requestSendForm";

interface ContactFormProps {
  buttonTitle?: string;
  icon?: ReactNode;
}

const phoneRegExp = /^\+38 \(0\d{2}\) \d{3}-\d{2}-\d{2}$/;

const FormSchema = yup.object({
  name: yup.string().required("Обов'язкове поле"),
  phone: yup
    .string()
    .required("Обов'язкове поле")
    .matches(phoneRegExp, "Неправильний формат телефону"),
  description: yup.string(),
});

const ContactForm: FC<ContactFormProps> = ({
  buttonTitle = "Отримати Консультацію",
  icon,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { values, errors, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {
      name: "",
      phone: "",
      description: "",
    },
    validationSchema: FormSchema,
    validateOnChange: false,
    onSubmit,
  });

  useEffect(() => {
    if (!isOpenModal) {
      resetForm();
    }
  }, [isOpenModal]);

  async function onSubmit(values: any) {
    try {
      setIsLoading(true);
      await sendForm(values);

      toast("Очікуйте дзвінка впродовж наступних 24 годин", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setIsOpenModal(false);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Button
        label={buttonTitle}
        contact={!icon}
        icon={icon}
        onClick={() => setIsOpenModal(true)}
      />

      <Modal
        isOpen={isOpenModal}
        onRequestClose={() => setIsOpenModal(false)}
        contentLabel="Example Modal"
        overlayClassName={styles.modal_overlay}
        className={styles.modal_modal}
      >
        <div>
          <header className={styles.modal_header}>
            <p className={styles.modal_header_title}>Замовити дзвінок</p>
            <div
              onClick={() => setIsOpenModal(false)}
              className={styles.modal_header_cross}
            >
              <ICONS.Cross fill="black" width={15} />
            </div>
          </header>

          <div>
            <Input
              value={values.name}
              //   label="Ім'я"
              placeholder="Ім'я"
              error={errors.name}
              onChange={(name) => setFieldValue("name", name)}
            />
          </div>
          <div>
            <PhoneInput
              value={values.phone}
              placeholder="Телефон"
              error={errors.phone}
              onChange={(phone) => setFieldValue("phone", phone)}
            />
          </div>
          <div>
            <Textarea
              value={values.description}
              placeholder="Опис"
              error={errors.description}
              onChange={(description) =>
                setFieldValue("description", description)
              }
            />
          </div>

          <Button
            label="Замовити дзвінок"
            small
            loading={isLoading}
            onClick={handleSubmit}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ContactForm;
