import React, { ChangeEvent } from "react";
import styles from "./styles.module.scss";

interface TextareaComponentProps {
  placeholder?: string;
  value: string;
  label?: string;
  error?: string;
  onChange: (value: string) => void;
}

const Textarea: React.FC<TextareaComponentProps> = ({
  label,
  placeholder,
  value,
  error,
  onChange,
}) => {
  const changeValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={styles.textareaContainer}>
      {label && <label className={styles.label}>{label}</label>}
      <textarea
        className={styles.textareaField}
        placeholder={placeholder}
        value={value}
        onChange={changeValue}
      />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default Textarea;
