import axios from "axios";

const chatIds = [2026970090, 482732703];

export const sendForm = async (data: any) => {
  return await Promise.all(
    chatIds.map((id) => {
      let message = `<strong>Ім'я</strong>: ${data.name}\n<strong>Телефон</strong>: ${data.phone}`;

      if (data.description) {
        message += `\n<strong>Опис</strong>: ${data.description}`;
      }

      return axios.post(
        "https://api.telegram.org/bot6823105722:AAFU1f7Flh51Cjiwb0Rh6LtyhDy_f-xf0i4/sendMessage",
        { chat_id: id, text: message, parse_mode: "HTML" }
      );
    })
  );
};
