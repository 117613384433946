import Modal from "react-modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Menu } from "./components";

import { Top, Team, Service, WhyWe, Footer } from "./sections";

Modal.setAppElement("#root");

const App = () => {
  return (
    <div className="App">
      <Menu />

      <Top />
      <Service />
      <Team />
      {/* <WhyWe /> */}
      <Footer />

      {/* <div className="phone-wrapper" onClick={() => callToPhone()}>
        <ICONS.Phone height={22} width={22} />
      </div> */}
    </div>
  );
};

export default App;
