import { ReactComponent as Doc } from "./doc.svg";
import { ReactComponent as DocTwo } from "./docTwo.svg";
import { ReactComponent as Equel } from "./equel.svg";
import { ReactComponent as Profile } from "./profile.svg";
import { ReactComponent as Money } from "./money.svg";
import { ReactComponent as Chat } from "./chat.svg";

const We = {
  Doc,
  Equel,
  DocTwo,
  Profile,
  Money,
  Chat,
};

export default We;
