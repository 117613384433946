import { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { IMAGES, ICONS } from "../../assets";

const Items = [
  {
    icon: ICONS.We.Doc,
    label: "Більше 150 зареєстрованих бізнес-проектів",
  },
  {
    icon: ICONS.We.Equel,
    label: "Досвід роботи в сфері права – понад 15 років",
  },
  {
    icon: ICONS.We.DocTwo,
    label:
      "Одна з перших оформлених ліцензій на онлайн загальноосвітню школу в Україні",
  },
  {
    icon: ICONS.We.Profile,
    label: "Постійні клієнти в роботі – стабільно 80 % від усіх клієнтів",
  },
  {
    icon: ICONS.We.Money,
    label: "Найбільша сума угоди з нашим супроводом – 2 000 000 $",
  },
  {
    icon: ICONS.We.Chat,
    label: "Робочі мови – Українська /English",
  },
];

const WhyWe: FC = () => {
  return (
    <section
      className={styles.wrapper}
      style={{ backgroundImage: `url(${IMAGES.law})` }}
      id="whywe"
    >
      <div className="container">
        <h2 className={cls("subtitle", styles.wrapper_title)}>Чому ми?</h2>

        <div className={styles.wrapper_block}>
          {Items.map((item, index) => (
            <div key={index} className={styles.wrapper_block_item}>
              <div className={styles.wrapper_block_item_icon}>
                <item.icon height={50} />
              </div>
              <div className={styles.wrapper_block_item_label}>
                {item.label}
              </div>
            </div>
          ))}

          {/* <div className={styles.wrapper_block_item}>Some</div>
          <div className={styles.wrapper_block_item}>Some</div>
          <div className={styles.wrapper_block_item}>Some</div>
          <div className={styles.wrapper_block_item}>Some</div>
          <div className={styles.wrapper_block_item}>Some</div>
          <div className={styles.wrapper_block_item}>Some</div> */}
        </div>
      </div>
    </section>
  );
};

export default WhyWe;
