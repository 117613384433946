import { FC, useState } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";

import { Button } from "../../../components";
import { ICONS } from "../../../assets";

interface ItemProps {
  title: string;
  fullList: string[];
  shortList: string[];
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    border: "none",
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
};

const Item: FC<ItemProps> = ({ title, shortList, fullList }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className={styles.wrapper}>
        <h3 className={styles.wrapper_title}>{title}</h3>

        <ul className={styles.wrapper_list}>
          {shortList.map((item, index) => (
            <li key={index} className={styles.wrapper_list_item}>
              {item}
            </li>
          ))}
        </ul>

        <div className={styles.wrapper_button}>
          <Button label="Детальніше" small onClick={() => setIsOpen(true)} />
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Example Modal"
        overlayClassName={styles.modal_overlay}
        className={styles.modal_modal}
      >
        <div>
          <header className={styles.modal_header}>
            <p className={styles.modal_header_title}>{title}</p>
            <div
              onClick={() => setIsOpen(false)}
              className={styles.modal_header_cross}
            >
              <ICONS.Cross fill="black" width={15} />
            </div>
          </header>

          <ul className={styles.modal_list}>
            {fullList.map((item, index) => (
              <li key={index} className={styles.modal_list_item}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default Item;
