import { FC, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import styles from "./styles.module.scss";

import { ICONS } from "../../assets";

import { ContactForm } from "../";

import { callToPhone } from "../../utils";

const Menu: FC = () => {
  const [isOpenHamburgerMenu, setIsOpenHamburgerMenu] =
    useState<boolean>(false);

  return (
    <>
      <div className={styles.container}>
        <div className="container">
          <div className={styles.wrapper}>
            <a href="#">
              <ICONS.Logo />
            </a>

            <div className={styles.wrapper_right}>
              <nav className={styles.wrapper_nav}>
                <AnchorLink
                  href="#aboutus"
                  offset={50}
                  className={styles.wrapper_nav_item}
                >
                  Про Нас
                </AnchorLink>
                <AnchorLink
                  href="#service"
                  offset={50}
                  className={styles.wrapper_nav_item}
                >
                  Послуги
                </AnchorLink>
                <AnchorLink
                  href="#team"
                  offset={50}
                  className={styles.wrapper_nav_item}
                >
                  Команда
                </AnchorLink>
                <AnchorLink
                  href="#footer"
                  offset={50}
                  className={styles.wrapper_nav_item}
                >
                  Контакти
                </AnchorLink>
              </nav>

              <div className={styles.wrapper_right_button}>
                <ContactForm />
              </div>
              {/* <button
                className={styles.wrapper_button}
                onClick={() => callToPhone("+380932539432")}
              >
                Отримати Консультацію
              </button> */}

              <div
                className={styles.wrapper_nav_hamburger}
                onClick={() => setIsOpenHamburgerMenu(true)}
              >
                <ICONS.Hamburger fill="white" height={30} />
              </div>
            </div>
          </div>

          <div className={styles.info}>
            <div className={styles.info_item}>
              <a
                className={styles.info_item_label}
                href="https://www.google.com/maps/place/Solister/@49.8412442,24.0165639,15z/data=!4m6!3m5!1s0x473add91c622f95b:0xb574b9626211fe18!8m2!3d49.8412442!4d24.0165639!16s%2Fg%2F11h0bklj6b?entry=ttu"
                target="_blank"
              >
                <ICONS.Location />
                <p className={styles.info_item_label_title}>Розташування</p>
              </a>

              <p className={styles.info_item_value}>
                м. Львів, вул. М. Гоголя 12/4
              </p>
            </div>

            <div className={styles.info_item}>
              <a className={styles.info_item_label} href="tel:+380676728786">
                <ICONS.Phone />
                <p className={styles.info_item_label_title}>Контакти</p>
              </a>

              <p className={styles.info_item_value}>+38 (067) 672 87 86</p>
            </div>
          </div>
        </div>
      </div>

      {isOpenHamburgerMenu && (
        <div className={styles.hamburger_menu}>
          <nav className={styles.hamburger_menu_nav}>
            <AnchorLink
              href="#aboutus"
              offset={50}
              className={styles.hamburger_menu_nav_item}
              onClick={() => {
                setIsOpenHamburgerMenu(false);
              }}
            >
              Про Нас
            </AnchorLink>
            <AnchorLink
              href="#team"
              offset={50}
              className={styles.hamburger_menu_nav_item}
              onClick={() => {
                setIsOpenHamburgerMenu(false);
              }}
            >
              Команда
            </AnchorLink>
            <AnchorLink
              href="#service"
              offset={50}
              className={styles.hamburger_menu_nav_item}
              onClick={() => {
                setIsOpenHamburgerMenu(false);
              }}
            >
              Послуги
            </AnchorLink>

            <AnchorLink
              href="#footer"
              offset={50}
              className={styles.hamburger_menu_nav_item}
              onClick={() => {
                setIsOpenHamburgerMenu(false);
              }}
            >
              Контакти
            </AnchorLink>
          </nav>

          <div
            className={styles.hamburger_menu_cross}
            onClick={() => setIsOpenHamburgerMenu(false)}
          >
            <ICONS.Cross color="white" />
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;
