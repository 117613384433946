import { FC, ReactNode } from "react";
import cls from "classnames";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./styles.module.scss";

interface ButtonProps {
  label: string;
  icon?: ReactNode;
  small?: boolean;
  contact?: boolean;
  loading?: boolean;
  onClick: () => void;
}

const Button: FC<ButtonProps> = ({
  label,
  icon,
  small = false,
  contact = false,
  loading = false,
  onClick,
}) => {
  return (
    <button
      className={cls(styles.button, {
        [styles.button_small]: small,
        [styles.button_contact]: contact,
      })}
      disabled={loading}
      onClick={onClick}
    >
      {loading ? (
        <ClipLoader size={12} color="black" />
      ) : (
        <>
          {icon ? <div className={styles.button_icon}>{icon}</div> : null}
          {label}
        </>
      )}
    </button>
  );
};

export default Button;
