import { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { IMAGES, ICONS } from "../../assets";

import { Button, ContactForm } from "../../components";
import { callToPhone } from "../../utils";

const Top: FC = () => {
  return (
    <div
      id="aboutus"
      className={styles.wrapper}
      style={{ backgroundImage: `url(${IMAGES.topBanner})` }}
    >
      <div className={cls(styles.container, "container")}>
        <h1 className={styles.container_title}>
          «Solister» — твій досвідчений та прогресивний юридичний партнер
        </h1>

        <p className={styles.container_description}>
          Спеціалізуємось на наданні компетентних правових послуг у різних
          сферах та пишаємося тим, що:
        </p>

        <ul className={styles.container_list}>
          <li>
            Наш досвід ґрунтується на понад 15 роках успішної практики в галузі
            права
          </li>
          <li>Більше 150-ти зареєстрованих бізнес-проектів за плечима</li>
          <li>
            У роботі ми відмовляємося від використання стандартних шаблонів,
            оскільки кожна ситуація є унікальною та вимагає індивідуального
            підходу. Саме тому наша команда завжди прагне використовувати
            нестандартні методи вирішення кожного правового питання
          </li>
          <li>
            Понад 80% наших клієнтів є постійними, що свідчить про високий
            рівень задоволеності нашими послугами
          </li>
          <li>Найбільша сума угоди з нашим супроводом — 6 000 000 UAH</li>
          <li>Мови спілкування — українська / англійська</li>
        </ul>

        <div className={styles.container_footer}>
          <ContactForm
            buttonTitle="Отримати консультацію"
            icon={<ICONS.Phone fill="black" />}
          />
          {/* <Button
            label="Отримати консультацію"
            icon={<ICONS.Phone fill="black" />}
            onClick={() => callToPhone("+380676728786")}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Top;
