import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as Linkedin } from "./linkedin.svg";
import { ReactComponent as Instagram } from "./instagram.svg";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as Phone } from "./phone.svg";
import { ReactComponent as Message } from "./message.svg";
import { ReactComponent as Marker } from "./marker.svg";
import { ReactComponent as Hamburger } from "./hamburger.svg";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as Arrow } from "./arrow.svg";
import { ReactComponent as ArrowLeft } from "./arrowLeft.svg";
import { ReactComponent as ArrowRight } from "./arrowRight.svg";

import We from "./we";

const ICONS = {
  Logo,
  Facebook,
  Linkedin,
  Instagram,
  Location,
  Phone,
  Message,
  Marker,
  Hamburger,
  Cross,
  Arrow,
  ArrowLeft,
  ArrowRight,

  We,
};

export default ICONS;
